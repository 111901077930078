<template>
   <div class="suspend">
          <div class="bg_gray"></div>
          <div class="box">
              <headbox
                title="浮悬窗报价"
                bgColor="#f5f5f5"
                :isbackPath="true"
                :path="path"
              />
                 <div class="card">
                 <div class="quotedPrice flex_between">
                    <div class="text">浮悬窗报价</div>
                    <div class="open">
                      <van-switch :value="suspend" @input="onInput" active-color="#fea89d" inactive-color="#dcdee0" />
                    </div>
                 </div>
                 <div class="category flex_between">
                   <div class="text">悬浮窗显示品种</div>
                    <div class="catgoryText flex_between" @click="changeCatgory">
                         <div class="text">{{priceText == 'huangjin9999'?'黄金':'伦敦金'}}</div>
                          <van-icon name="arrow" color="#999999" />
                    </div>
                 </div>
                 </div>
          </div>
   </div>
</template>

<script>
import headbox from "@/components/head.vue";
import { mapState } from 'vuex'
export default {
  components: {
    headbox
  },
  data() {
    return {
      priceText: localStorage.getItem('changeCatgoryName')|| "伦敦金",
      path:''
    }
  },
    computed: {
    ...mapState(['suspend']),
  },
  mounted() {
    this.path = this.$route.query.backPath
  },
  methods:{
    changeCatgory(){
        this.$router.push({name:'changeCatgory'})
    },
    onInput(checked){
        this.$store.dispatch('getchangeSuspend',checked)
        localStorage.setItem('suspend',checked)

        this.$emit('suspends',checked)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .van-switch{
    height: 20px;
  }
  .van-switch__node{
    background-color: #f84e38;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    top: -5px;
  }
  .van-icon{
    top: 5px;
  }
}
.bg_gray {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.box{
  width: 100%;
  position: relative;
  z-index: 1;
    .card{
        width: 90%;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 12px;
        padding: 20px;
        color: #212121;
        font-size: 28px;
        .category{
          padding-top: 20px;
          margin-top: 20px;
          border-top: 1px solid #e5e5e5;
        }
    }
}
.flex_between{
  display: flex;
  justify-content: space-between;
  
}
.catgoryText{
  font-size: 24px;
  color: #999;
  width: 110px;
}
</style>